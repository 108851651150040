import { makeStyles, shorthands, tokens, typographyStyles } from '@configs/theme/v9';
export const useLeaderAnnotationStyles = makeStyles({
    annotationSection: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%'
    },

    annotationHeader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'start',
        flexWrap: 'wrap'
    },
    annotationText: {
        ...typographyStyles.body2
    },
    userInfo: {
        '& .fui-Persona__primaryText': {
            ...typographyStyles.body2,
            lineHeight: tokens.lineHeightBase500
        }
    },
    text: {
        ...shorthands.padding(0, '8px'),
        ...typographyStyles.subtitle2,
        lineHeight: tokens.lineHeightBase500
    }
});
