import { makeStyles, tokens, shorthands } from '@configs/theme/v9';

export const interactionClass = 'ui-eff';
export const useTileCardStyles = makeStyles({
    root: {
        ...shorthands.padding('16px'),
        boxSizing: 'border-box',
        ...shorthands.overflow('hidden'),
        display: 'flex',
        justifyContent: 'center'
    },
    rootCompact: {
        ...shorthands.padding('8px'),
        boxSizing: 'border-box',
        ...shorthands.overflow('hidden'),
        display: 'flex',
        justifyContent: 'center'
    },
    inner: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'start',
        ...shorthands.gap('16px', '24px'),
        ...shorthands.padding('16px'),
        color: tokens.colorNeutralForeground2,
        backgroundColor: tokens.colorNeutralBackground1,
        boxShadow: tokens.shadow4Brand,
        width: '100%',
        maxWidth: '360px',
        boxSizing: 'border-box',
        ...shorthands.borderRadius('8px'),
        ...shorthands.border('1px', 'solid', tokens.transparentWhite),
        cursor: 'pointer',
        ':hover': {
            color: tokens.colorNeutralForeground1Hover,
            backgroundColor: tokens.colorTransparentBackgroundHover,

            [`& .${interactionClass}`]: {
                backgroundColor: tokens.colorTransparentBackgroundHover,
                color: tokens.colorNeutralForeground1Hover
            }
        }
    },
    mobile: { width: '100%' },
    critical: {
        backgroundColor: tokens.criticalCustomBackground
    },
    titleWrapper: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: '50px',
        width: '100%',
        ...shorthands.gap('12px', '0px')
    },
    title: {
        fontWeight: 700,
        ...shorthands.margin(0),
        ...shorthands.flex(1),
        color: tokens.colorNeutralForeground2Link
    },
    titleIcon: {
        minWidth: '24px',
        width: '24px',
        height: '24px',
        color: tokens.colorNeutralForeground2Link
    },
    description: {
        fontWeight: 400,
        height: '40px',
        ...shorthands.margin(0)
    },
    multiLine: {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        ...shorthands.overflow('hidden'),
        '@supports (-webkit-line-clamp: 2)': {
            whiteSpace: 'initial',
            display: '-webkit-box',
            WebkitLineClamp: 2,
            WebkitBoxOrient: 'vertical'
        }
    },
    footerWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        columnGap: '10px'
    },
    image: {
        width: '32px',
        height: '32px',
        backgroundColor: tokens.imageBackground,
        ...shorthands.borderRadius('16px')
    }
});
