import * as React from 'react';
import { MessagesViewType } from '@models/ui/MessagesViewType';
import { SizeModeType } from '@models/ui/SizeModeType';
import TabletActionsBar from './TabletActionBar';
import FullActionBar from './FullActionBar';
import MobileActionsBar from './MobileActionBar';

export interface IActionsBarProps {
    mainZoneMessage?: string;
    secondaryZoneMessage?: string;
    searchComponent?: React.ReactElement;
    additionalActionsComponent?: React.ReactElement;
    filtersComponent?: React.ReactElement;
    extraFiltersComponent?: React.ReactElement;
    filtersTagsComponent?: React.ReactElement;
    viewConfig?: {
        current: MessagesViewType;
        onChange: (view: MessagesViewType) => void;
    };
    sizeMode: SizeModeType;
}

const ActionsBar = (props: IActionsBarProps): React.ReactElement => {
    switch (props.sizeMode) {
        case SizeModeType.Extra:
        case SizeModeType.Large: {
            return <FullActionBar {...props} />;
        }
        case SizeModeType.Tablet: {
            return <TabletActionsBar {...props} />;
        }
        default: {
            return <MobileActionsBar {...props} />;
        }
    }
};

export default ActionsBar;
