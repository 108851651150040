import React from 'react';
import { Field, Textarea, TextareaOnChangeData, TextareaProps } from '@fluentui/react-components';

interface ITextAreaControlProps {
    id?: string;
    onValueChanged: (value: string) => void;
    fieldClassName?: string;
    className?: string;
    value: string;
    maxLength: number;
    placeholder: string;
    label?: string;
    maxLengthMessagePostfix: string;
    required?: boolean;
    rows?: number;
    appearance?:
        | 'filled-darker'
        | 'outline'
        | 'filled-lighter'
        | 'filled-darker-shadow'
        | 'filled-lighter-shadow'
        | undefined;
}

const TextAreaControl: React.FC<ITextAreaControlProps> = ({
    id,
    maxLength,
    onValueChanged,
    placeholder,
    value,
    fieldClassName,
    maxLengthMessagePostfix,
    appearance,
    label,
    required = false,
    rows = 6,
    className
}) => {
    const onTextChange: TextareaProps['onChange'] = React.useCallback(
        (ev: React.ChangeEvent<HTMLTextAreaElement>, data: TextareaOnChangeData) => {
            if (!!data.value) {
                const val =
                    data.value.length <= maxLength
                        ? data.value
                        : data.value.substring(0, maxLength);
                console.log(val.length);
                onValueChanged(val);
            } else {
                onValueChanged('');
            }
        },
        [onValueChanged, maxLength]
    );
    return (
        <Field
            id={id}
            className={fieldClassName}
            label={label}
            hint={
                !!maxLengthMessagePostfix
                    ? ` ${maxLength - (!!value ? value.length : 0)} ${maxLengthMessagePostfix}`
                    : ''
            }
        >
            <Textarea
                className={className}
                placeholder={placeholder || ''}
                onChange={onTextChange}
                rows={rows}
                appearance={appearance}
                value={value || ''}
                required={required}
            />
        </Field>
    );
};

export default TextAreaControl;
