import { MessageInstanceAdminBasicData } from '@api/models/MessageInstanceAdminBasicData';
import {
    TableColumnDefinition,
    TableColumnId,
    TableHeaderCellProps,
    TableRowData,
    createTableColumn,
    useTableFeatures,
    useTableSort
} from '@fluentui/react-components';
export type MessageInstanceAdminBasicDataKeys = keyof MessageInstanceAdminBasicData;

const importanceLevelColumnId: MessageInstanceAdminBasicDataKeys = 'critical';
const leaderColumnId: MessageInstanceAdminBasicDataKeys = 'leader';
const titleColumnId: MessageInstanceAdminBasicDataKeys = 'title';
const statusColumnId: MessageInstanceAdminBasicDataKeys = 'status';
const instancePublishingDateUTCColumnId: MessageInstanceAdminBasicDataKeys =
    'instancePublishingDateUTC';

const columns: TableColumnDefinition<MessageInstanceAdminBasicData>[] = [
    createTableColumn<MessageInstanceAdminBasicData>({
        columnId: titleColumnId,
        compare: (a, b) => {
            return a.title.localeCompare(b.title);
        }
    }),
    createTableColumn<MessageInstanceAdminBasicData>({
        columnId: leaderColumnId,
        compare: (a, b) => {
            return a.leader.name.localeCompare(b.leader.name);
        }
    }),

    createTableColumn<MessageInstanceAdminBasicData>({
        columnId: statusColumnId,
        compare: (a, b) => {
            return a.status.toString().localeCompare(b.status.toString());
        }
    }),

    createTableColumn<MessageInstanceAdminBasicData>({
        columnId: importanceLevelColumnId,
        compare: (a, b) => {
            return `${b.critical}`.localeCompare(`${a.critical}`);
        }
    }),
    createTableColumn<MessageInstanceAdminBasicData>({
        columnId: instancePublishingDateUTCColumnId,
        compare: (a, b) => {
            return +(a.instancePublishingDateUTC > b.instancePublishingDateUTC);
        }
    })
];

const useTableConfiguration = (
    items: MessageInstanceAdminBasicData[]
): {
    rows: TableRowData<MessageInstanceAdminBasicData>[];
    headerSortProps: (columnId: TableColumnId) => Partial<TableHeaderCellProps>;
} => {
    const {
        getRows,
        sort: { getSortDirection, toggleColumnSort, sort }
    } = useTableFeatures(
        {
            columns,
            items
        },
        [useTableSort({})]
    );

    const headerSortProps = (columnId: TableColumnId): Partial<TableHeaderCellProps> => ({
        onClick: (e: React.MouseEvent) => {
            toggleColumnSort(e, columnId);
        },

        sortDirection: getSortDirection(columnId)
    });

    const rows = sort(getRows());

    return {
        rows,
        headerSortProps
    };
};

const ColumnsIds = {
    titleColumnId,
    leaderColumnId,
    instancePublishingDateUTCColumnId,
    statusColumnId,
    importanceLevelColumnId
};

export { useTableConfiguration, ColumnsIds };
