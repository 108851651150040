import * as React from 'react';
import { SubtractCircle24Regular } from '@fluentui/react-icons';
import { useTranslation } from 'react-i18next';
import { Text } from '@fluentui/react-components';
import { useAppDispatch } from '@state';
import { ConfirmationDialogAction } from '@components/ConfirmationDialogAction';
import { markMessageIrrelevant } from '@state/selectedMessage';
import TextAreaControl from '@components/controls/common/TextArea';

const TOTAL_LIMIT = 2000;

const MarkIrrelevantAction = (): React.ReactElement => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const [feedbackText, setFeedbackText] = React.useState('');

    const onMarkAsIrrelevant = React.useCallback(async (): Promise<void> => {
        dispatch(markMessageIrrelevant(feedbackText));
    }, [dispatch, feedbackText]);

    return (
        <ConfirmationDialogAction
            dialogTitle={t('ConfirmationDialog.IrrelevantDialogTitle')}
            dialogTriggerButtonLabel={t('MessageInteraction.CommsStep.MarkIrrelevantBtnLabel')}
            dialogTriggerButtonProps={{
                appearance: 'outline',
                icon: <SubtractCircle24Regular />
            }}
            isConfirmReady={() => !!feedbackText}
            onAction={onMarkAsIrrelevant}
        >
            <Text as="p">{t('ConfirmationDialog.IrrelevantDetailsLabel')}</Text>

            <TextAreaControl
                placeholder={t('ConfirmationDialog.IrrelevantDetailsPlaceholder') ?? ''}
                rows={6}
                required={true}
                maxLength={TOTAL_LIMIT}
                maxLengthMessagePostfix={t('ConfirmationDialog.IrrelevantDetailsTextLimit') || ''}
                appearance="filled-darker"
                onValueChanged={setFeedbackText}
                value={feedbackText || ''}
            />
        </ConfirmationDialogAction>
    );
};

export default MarkIrrelevantAction;
