import * as React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Suspense, useEffect, useState } from 'react';

import MyMessagesPage from './pages/MyMessagesPage';

import { routes } from './configs/pages';
import styles from './App.module.scss';
import { ThemeProvider } from '@fluentui/react';
import { FluentProvider } from '@fluentui/react-components';
import { app } from '@microsoft/teams-js';
import i18n, { updateLocale } from './i18n';
import { AppThemeV8Context, getV8TeamsTheme } from '@configs/theme/v8';
import { AppThemeV9Context, getV9TeamsThemeByName } from '@configs/theme/v9';
import ErrorPage from './pages/ErrorPage';
import AdminPage from './pages/AdminPage';
import DesignatedApproverPage from './pages/DesignatedApproverPage';
import NotFoundPage from './pages/NotFoundPage';
import MoreMessagesPage from './pages/MoreMessagesPage';
import NoAccessPage from './pages/NoAccessPage';

export const App = (): React.ReactElement => {
    const [theme, setTheme] = useState<string | undefined>(undefined);
    const [initId, setInitId] = useState<number | undefined>(undefined);
    useEffect(() => {
        const init = async (): Promise<void> => {
            if (!app.isInitialized()) {
                await app.initialize();
            }
            const context = await app.getContext();
            if (context.page.subPageId) {
                setInitId(parseInt(context.page.subPageId));
            }
            app.registerOnThemeChangeHandler((t) => {
                setTheme(t);
            });
            setTheme(context.app?.theme);
        };

        init().catch(console.log);

        updateLocale();
    }, []);

    if (!theme) return <></>;

    const v9Theme = getV9TeamsThemeByName(theme);
    const v8Theme = getV8TeamsTheme(v9Theme);

    return (
        <Suspense fallback={<div></div>}>
            <AppThemeV8Context.Provider value={v8Theme}>
                <AppThemeV9Context.Provider value={v9Theme}>
                    <div id="plct-app" className={styles.app}>
                        <ThemeProvider theme={v8Theme} className={styles.themeProvider}>
                            <FluentProvider
                                theme={v9Theme}
                                className={styles.themeProvider}
                                dir={i18n.dir()}
                            >
                                <Routes>
                                    <Route path={routes.noAccess} element={<NoAccessPage />} />
                                    <Route
                                        path={routes.myMessages}
                                        element={<MyMessagesPage initId={initId} />}
                                    />
                                    <Route
                                        path={routes.moreMessages}
                                        element={<MoreMessagesPage />}
                                    />
                                    <Route path={routes.admin} element={<AdminPage />} />
                                    <Route
                                        path={routes.designatedApprover}
                                        element={<DesignatedApproverPage />}
                                    />
                                    <Route path={routes.errorPageid} element={<ErrorPage />} />
                                    <Route path={routes.errorPage} element={<ErrorPage />} />

                                    <Route path="/*" element={<NotFoundPage />} />
                                </Routes>
                            </FluentProvider>
                        </ThemeProvider>
                    </div>
                </AppThemeV9Context.Provider>
            </AppThemeV8Context.Provider>
        </Suspense>
    );
};

export default App;
