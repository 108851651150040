import { MessageInstanceFullContentModel } from '@models/data/MessageInstanceFullContentModel';
import * as React from 'react';
import { useMessageContentStepStyles } from './MessageContentStep.styles';
import {
    ArrowCircleRight24Regular,
    DismissCircle24Regular,
    PersonFeedback24Regular
} from '@fluentui/react-icons';
import { useTranslation } from 'react-i18next';
import { Button } from '@fluentui/react-components';
import MessageInteractionActions from '../MessageInteractionActions';
import { useAppDispatch } from '@state';
import { cancelMessageInteraction, openCommunicationStep } from '@state/selectedMessage';
import { FeedbackDialogAction } from '@components/FeedbackDialogAction';
import { MessageAdditionalInfoSection } from './MessageAdditionalInfo';
import { MessageInteractionMode } from '@models/data/MessageInteractionMode';
import { SizeModeType } from '@models/ui/SizeModeType';
import MarkIrrelevantAction from './MarkIrrelevantAction';
import MessageImage from './MessageImage';
import { MessageBanner } from '../MessageBanner';
import { FindOutMoreButton } from './FindOutMoreButton';
import { LeaderAnnotation } from './LeaderAnnotation';
import { MessageInstanceLeaderStatus } from '@api/models';
import { feedbackLocations } from '@configs/feedbackLocations';
import { app } from '@microsoft/teams-js';

export interface IMessageContentStepProps {
    message: MessageInstanceFullContentModel;
    mode: MessageInteractionMode;
    sizeMode: SizeModeType;
}

const MessageContentStep = ({
    mode,
    message,
    sizeMode
}: IMessageContentStepProps): React.ReactElement => {
    const dispatch = useAppDispatch();
    const styles = useMessageContentStepStyles();
    const { t } = useTranslation();

    const isAdminView = mode === MessageInteractionMode.Admin;
    const isFull = sizeMode === SizeModeType.Extra || sizeMode === SizeModeType.Large;

    const contentDivRef = React.useRef<HTMLDivElement>(null);

    const onClose = React.useCallback(() => dispatch(cancelMessageInteraction()), [dispatch]);
    const onOpenCommunicationStep = React.useCallback(
        () => dispatch(openCommunicationStep()),
        [dispatch]
    );

    const handleLinkClick = React.useCallback(
        async (url: string, event: MouseEvent): Promise<void> => {
            event.preventDefault();
            if (!app.isInitialized) {
                await app.initialize();
            }
            app.openLink(url);
        },
        []
    );

    React.useEffect(() => {
        if (!!contentDivRef.current) {
            const links = contentDivRef.current.querySelectorAll('a');
            links.forEach((link) =>
                (link as HTMLAnchorElement).addEventListener('click', (event: MouseEvent) =>
                    handleLinkClick((link as HTMLAnchorElement).href, event)
                )
            );
        }
    });

    return (
        <div className={styles.root}>
            {!!message.imageUrl && isFull && (
                <div className={styles.leftColumn}>
                    <MessageImage imageTextAlt={message.imageTextAlt} imageUrl={message.imageUrl} />
                    <MessageAdditionalInfoSection message={message} isAdminView={isAdminView} />
                </div>
            )}
            <div className={styles.mainColumn}>
                <div className={styles.contentWrapper}>
                    <MessageBanner critical={message.critical} />
                    <div
                        ref={contentDivRef}
                        className={styles.messageContent}
                        dangerouslySetInnerHTML={{ __html: message.content }}
                    />

                    {(!message.imageUrl || !isFull) && (
                        <div className={styles.fullWidthSection}>
                            <div className={styles.imageCtn}>
                                <MessageImage
                                    imageTextAlt={message.imageTextAlt}
                                    imageUrl={message.imageUrl}
                                />
                            </div>
                            <MessageAdditionalInfoSection
                                message={message}
                                isAdminView={isAdminView}
                            />
                        </div>
                    )}
                    <FindOutMoreButton
                        sizeMode={sizeMode}
                        actionLabel={message.actionLabel}
                        actionLinkUrl={message.actionLinkUrl}
                    />
                    <LeaderAnnotation message={message} />
                </div>
                <MessageInteractionActions fullWidth={sizeMode === SizeModeType.Mobile}>
                    {mode !== MessageInteractionMode.ReadOnly && (
                        <>
                            <Button
                                onClick={onOpenCommunicationStep}
                                appearance="primary"
                                icon={<ArrowCircleRight24Regular />}
                            >
                                {isAdminView
                                    ? t('MessageInteraction.ContentStep.AdminActionBtnLabel')
                                    : t('MessageInteraction.ContentStep.ConfirmCommsBtnLabel')}
                            </Button>
                            {!message.mandatory &&
                                message.leaderStatus !== MessageInstanceLeaderStatus.Irrelevant && (
                                    <MarkIrrelevantAction />
                                )}
                            {!isAdminView && (
                                <FeedbackDialogAction
                                    dialogTriggerButtonText={t(
                                        'MessageInteraction.ContentStep.LeaveFeedbackButton'
                                    )}
                                    dialogTriggerButtonProps={{
                                        appearance: 'outline',
                                        icon: <PersonFeedback24Regular />
                                    }}
                                    defaultFeedbackType="Message feedback"
                                    feedbackLocation={feedbackLocations.message(message.title)}
                                />
                            )}
                        </>
                    )}
                    <Button
                        onClick={onClose}
                        appearance="outline"
                        icon={<DismissCircle24Regular />}
                    >
                        {t('MessageInteraction.ContentStep.CancelButton')}
                    </Button>
                </MessageInteractionActions>
            </div>
        </div>
    );
};

export default MessageContentStep;
