import React, { useState } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTitle,
    DialogTrigger,
    Dropdown,
    Field,
    Option,
    Text,
    useId,
    DropdownProps,
    ButtonProps
} from '@fluentui/react-components';
import { useTranslation } from 'react-i18next';

import { postFeedback } from '@api/services/feedback';
import { useFeedbackDialogStyles } from './FeedbackDialogAction.styles';
import { getfeedbackTypes } from '@configs/feedbackTypes';
import { TitleValuePair } from '@models/data/TitleValuePair';
import { Dismiss24Regular, Comment16Regular } from '@fluentui/react-icons';
import TextAreaControl from '@components/controls/common/TextArea';

const TOTAL_LIMIT = 2000;

export interface IFeedbackDialogActionProps {
    iconOnlyButton?: boolean;
    dialogTriggerButtonProps?: ButtonProps;
    dialogTriggerButtonText?: string | null;
    defaultFeedbackType?: string;
    feedbackLocation: string;
}

export const FeedbackDialogAction = ({
    iconOnlyButton,
    dialogTriggerButtonProps,
    dialogTriggerButtonText,
    defaultFeedbackType,
    feedbackLocation
}: IFeedbackDialogActionProps): React.ReactElement => {
    const feedbackTypeFieldId = useId('feedback-type');
    const feedbackContentFieldId = useId('feedback-content');

    const styles = useFeedbackDialogStyles();
    const { t } = useTranslation();
    const feedbackTypes = getfeedbackTypes(t);

    const [feedbackType, setFeedbackType] = useState<TitleValuePair<string> | undefined>(
        feedbackTypes.find((x) => x.id === defaultFeedbackType) ?? feedbackTypes[0]
    );
    const [open, setOpen] = React.useState(false);
    const [feedbackContent, setFeedbackContent] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const onOptionSelect: DropdownProps['onOptionSelect'] = (_, data) => {
        const key = data.optionValue;
        setFeedbackType(feedbackTypes.find((c) => c.id === key));
    };

    const onClose = (): void => {
        setOpen(false);
        setIsLoading(false);
        setFeedbackContent('');
        setFeedbackType(feedbackTypes[0]);
    };

    const onSubmit = async (): Promise<void> => {
        try {
            setIsLoading(true);
            await postFeedback({
                feedbackType: feedbackType!.id,
                feedbackContent,
                feedbackLocation
            });
            onClose();
        } catch {
            console.error('Error when submitting a feedback form!');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Dialog modalType="modal" open={open} onOpenChange={(_, data) => setOpen(data.open)}>
            <DialogTrigger disableButtonEnhancement>
                {iconOnlyButton ? (
                    <Button
                        appearance="outline"
                        size="medium"
                        className={styles.iconBtn}
                        icon={<Comment16Regular />}
                        iconPosition="before"
                    ></Button>
                ) : (
                    <Button
                        appearance="primary"
                        size="medium"
                        className={styles.btn}
                        {...dialogTriggerButtonProps}
                    >
                        {dialogTriggerButtonText ?? t('Common.Feedback.ProvideFeedbackLabel')}
                    </Button>
                )}
            </DialogTrigger>
            <DialogSurface role="form">
                <DialogBody>
                    <DialogTitle
                        action={
                            <DialogTrigger action="close">
                                <Button
                                    appearance="subtle"
                                    aria-label="close"
                                    icon={<Dismiss24Regular />}
                                />
                            </DialogTrigger>
                        }
                    >
                        {t('Common.Feedback.Dialog.Title')}
                    </DialogTitle>
                    <DialogContent style={{ gap: '15px' }} className={styles.dialogContent}>
                        <Text className={styles.text} as="p">
                            {t('Common.Feedback.Dialog.SubText1')}
                        </Text>
                        <Text className={styles.text} as="p">
                            {t('Common.Feedback.Dialog.SubText2')}
                        </Text>
                        <Text className={styles.text} as="p">
                            {t('Common.Feedback.Dialog.SubText3')}
                        </Text>
                        <Field
                            className={styles.field}
                            id={feedbackTypeFieldId}
                            label={t('Common.Feedback.Dialog.FeedbackType.Label')}
                        >
                            <Dropdown
                                aria-labelledby={feedbackTypeFieldId}
                                defaultValue={feedbackType?.title}
                                defaultSelectedOptions={feedbackType && [feedbackType.id]}
                                name="feedbackType"
                                appearance="filled-darker"
                                onOptionSelect={onOptionSelect}
                            >
                                {feedbackTypes.map((option) => (
                                    <Option key={option.id} value={option.id}>
                                        {option.title}
                                    </Option>
                                ))}
                            </Dropdown>
                        </Field>

                        <TextAreaControl
                            id={feedbackContentFieldId}
                            placeholder={t('ConfirmationDialog.IrrelevantDetailsPlaceholder') ?? ''}
                            label={t('Common.Feedback.Dialog.FeedbackContent.Label') || ''}
                            rows={6}
                            required={true}
                            maxLength={TOTAL_LIMIT}
                            maxLengthMessagePostfix={
                                t('Common.Feedback.Dialog.FeedbackContent.TextLimit') || ''
                            }
                            appearance="filled-darker"
                            onValueChanged={setFeedbackContent}
                            value={feedbackContent}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button
                            type="submit"
                            appearance="primary"
                            disabled={isLoading || !feedbackContent || !feedbackType}
                            onClick={onSubmit}
                        >
                            {t('Common.Feedback.Dialog.Submit')}
                        </Button>

                        <DialogTrigger disableButtonEnhancement>
                            <Button appearance="secondary" onClick={onClose}>
                                {t('Common.Feedback.Dialog.Cancel')}
                            </Button>
                        </DialogTrigger>
                    </DialogActions>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    );
};
