export const feedbackLocations = {
    myMessages: 'Outstanding messages',
    allMessages: 'All messages',
    designatedApprover: 'Designated Approver',
    message: (title: string) => {
        let m = `Message - "${title}"`;
        if (m.length > 255) {
            m = m.substring(0, 251) + `..."`;
        }

        return m;
    },
    messageIrrelevant: (title: string) => {
        let m = `Message irrelevant - "${title}"`;
        if (m.length > 255) {
            m = m.substring(0, 251) + `..."`;
        }
        return m;
    }
};
