import * as React from 'react';
import Search from '@components/controls/common/Search';
import { useAppDispatch, useStateSelector } from '@state';
import { setSearchBoxValue } from '@state/filters';
import { useTranslation } from 'react-i18next';
import { SizeModeType } from '@models/ui/SizeModeType';
import { useMessageSearchboxStyles } from './MessageSearchBox.styles';

export interface IMessageSearchBoxProps {
    searchValueChangedCallback: () => void;
}

export const MessageSearchBox = ({
    searchValueChangedCallback
}: IMessageSearchBoxProps): React.ReactElement => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const value = useStateSelector((x) => x.filters.searchBoxValue);
    const sizeMode = useStateSelector((x) => x.app.sizeMode);

    const onChanged = React.useCallback(
        (v: string) => {
            dispatch(setSearchBoxValue(v));
            searchValueChangedCallback();
        },
        [dispatch, searchValueChangedCallback]
    );

    const styles = useMessageSearchboxStyles();

    return sizeMode === SizeModeType.Extra || sizeMode === SizeModeType.Large ? (
        <Search
            placeholder={t('Common.ActionsPanel.FindLabel')}
            onChanged={onChanged}
            value={value}
            ariaLabel={t('Common.ActionsPanel.SearchArialabel') || ''}
            iconAfter
        />
    ) : (
        <Search
            className={styles.mobile}
            placeholder={t('Common.ActionsPanel.FindLabel')}
            onChanged={onChanged}
            value={value}
            iconAfter
            ariaLabel={t('Common.ActionsPanel.SearchArialabel') || ''}
        />
    );
};
