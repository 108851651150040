import * as React from 'react';
import { useEffect } from 'react';
import MessageTileView from '@components/MessageTileView';
import { usePageStyles } from './MyMessages.styles';

import { CommonMessageListView } from '@components/MessageListView';
import { MessagesViewType } from '@models/ui/MessagesViewType';
import { useAppDispatch, useStateSelector } from '@state';

import { loadActiveMessagesNumber, loadMyMessages } from '@state/messages';
import ActionsPanel from '@components/ActionsBar';

import { SizeModeType } from '@models/ui/SizeModeType';
import { MessageFiltersPanelAction } from '@components/MessageFiltersPanelAction';
import { FeedbackDialogAction } from '@components/FeedbackDialogAction';
import { MessageSearchBox } from '@components/controls/MessageSearchBox';

import { useTranslation } from 'react-i18next';
import { withPageLayout } from '../PageLayout';
import { loadMyMessage, setPreviewInitialized } from '@state/selectedMessage';
import { MessageInstanceBasicContentModel } from '@models/data/MessageInstanceBasicContentModel';
import Loader from '@components/controls/common/Loader';
import MessageFilterTags from '@components/MessageFilterTags';
import { mergeClasses } from '@fluentui/react-components';
import { useScrollBarStyle } from '@components/controls/common/scrollbar.styles';
import { feedbackLocations } from '@configs/feedbackLocations';
import { HelpLinkAction } from '@components/HelpLinkAction';

export interface IMyMessagePageProps {
    initId?: number;
}
const MyMessages: React.FunctionComponent<IMyMessagePageProps> = ({
    initId
}: IMyMessagePageProps) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const styles = usePageStyles();
    const scrollBarStyle = useScrollBarStyle();

    const { sizeMode, containerWidth } = useStateSelector((c) => c.app);
    const previewInitialized = useStateSelector((c) => c.selectedMessage.previewInitialized);
    const { messages, loading, messageCount, messageCountLoading } = useStateSelector(
        (x) => x.messages
    );

    const [activeView, setActiveView] = React.useState<MessagesViewType>(MessagesViewType.Tiles);

    const loadContent = React.useCallback(() => dispatch(loadMyMessages()), [dispatch]);
    const loadMessage = React.useCallback(
        (m: MessageInstanceBasicContentModel) => dispatch(loadMyMessage(m.id)),
        [dispatch]
    );
    const loadMessageNumber = React.useCallback(
        () => dispatch(loadActiveMessagesNumber()),
        [dispatch]
    );

    useEffect(() => {
        if (initId && !previewInitialized) {
            dispatch(setPreviewInitialized());
            dispatch(loadMyMessage(initId));
        }
    }, [previewInitialized, initId, dispatch]);

    useEffect(() => {
        loadContent();
        loadMessageNumber();
    }, [loadContent, loadMessageNumber]);

    let mainMessage = '';

    if (!messageCountLoading) {
        mainMessage = t('MyMessagesPage.MainMessage').replace(
            '{messageNumber}',
            messageCount.toString()
        );
    }

    const iconOnlyButtons = sizeMode !== SizeModeType.Extra && sizeMode !== SizeModeType.Large;

    const filtersComponent = (
        <MessageFiltersPanelAction
            applyFiltersCallback={loadContent}
            iconOnlyButton={iconOnlyButtons}
        />
    );
    const filtersTagsComponent =
        sizeMode === SizeModeType.Extra ? (
            <MessageFilterTags changeFiltersCallback={loadContent} />
        ) : (
            <></>
        );
    const searchComponent = <MessageSearchBox searchValueChangedCallback={loadContent} />;
    const additionalActionsComponent = (
        <div className={styles.customActions}>
            <FeedbackDialogAction
                iconOnlyButton={iconOnlyButtons}
                feedbackLocation={feedbackLocations.myMessages}
            />
            <HelpLinkAction iconOnlyButton={iconOnlyButtons} />
        </div>
    );

    return (
        <div className={styles.page}>
            <ActionsPanel
                mainZoneMessage={mainMessage}
                secondaryZoneMessage={t('MyMessagesPage.SecondaryMessage') || ''}
                sizeMode={sizeMode}
                viewConfig={{
                    current: activeView,
                    onChange: setActiveView
                }}
                filtersTagsComponent={filtersTagsComponent}
                additionalActionsComponent={additionalActionsComponent}
                searchComponent={searchComponent}
                filtersComponent={filtersComponent}
            />
            {loading ? (
                <Loader message={t('MyMessagesPage.MessagesLoading') || ''} />
            ) : (
                <div className={mergeClasses(styles.scrollable, scrollBarStyle)}>
                    {activeView === MessagesViewType.Tiles ? (
                        <MessageTileView
                            compact={sizeMode === SizeModeType.Mobile}
                            containerWidth={containerWidth}
                            items={messages}
                            onClick={loadMessage}
                        />
                    ) : (
                        <CommonMessageListView
                            compact={sizeMode === SizeModeType.Mobile}
                            items={messages}
                            onClick={loadMessage}
                        />
                    )}
                </div>
            )}
        </div>
    );
};

export default withPageLayout(MyMessages);
