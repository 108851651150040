import { mergeClasses } from '@fluentui/react-components';
import { useMessageViewHeaderStyles } from './MessageViewHeader.styles';
import { MessageInstanceFullContentModel } from '@models/data/MessageInstanceFullContentModel';
import * as React from 'react';
import { SizeModeType } from '@models/ui/SizeModeType';
import { CloseButton } from './CloseButton';
import { MessageTitle } from './MessageTitle';
import { MessagePublishDate } from './MessagePublishDate';
import { MessageMeta } from './MessageMeta';

export interface IMessageViewHeaderProps {
    message: MessageInstanceFullContentModel;
    sizeMode: SizeModeType;
}

const MessageViewHeader = ({ message, sizeMode }: IMessageViewHeaderProps): React.ReactElement => {
    const styles = useMessageViewHeaderStyles();

    const isFullMode = sizeMode === SizeModeType.Extra || sizeMode === SizeModeType.Large;

    return (
        <div className={styles.header}>
            {isFullMode ? (
                <div className={styles.flexRowItem}>
                    <div className={styles.flexRowItem}>
                        <MessageTitle title={message.title} />
                    </div>
                    <div className={mergeClasses(styles.flexRowItem, styles.autoWidth)}>
                        <MessageMeta message={message} fullMode={isFullMode} />
                        <CloseButton />
                    </div>
                </div>
            ) : (
                <>
                    <div className={styles.flexRowItem}>
                        <div className={mergeClasses(styles.flexRowItem, styles.flexGrowItem)}>
                            <MessageTitle title={message.title} />
                        </div>
                        <MessagePublishDate date={message.messagePublishingDateUTC} />
                        <CloseButton />
                    </div>
                    <div className={mergeClasses(styles.flexRowItem)}>
                        <MessageMeta message={message} fullMode={isFullMode} />
                    </div>
                </>
            )}
        </div>
    );
};

export default MessageViewHeader;
