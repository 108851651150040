export const routes = {
    myMessages: '/mymessages',
    myMessagesid: '/mymessages/:id',
    moreMessages: '/moremessages',
    errorPage: '/errorpage',
    errorPageid: '/errorpage/:id',
    admin: '/admin',
    designatedApprover: '/designatedapprover',
    noAccess: '/noaccess'
};
