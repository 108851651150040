/* eslint-disable max-len */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Subtitle2, Subtitle1 } from '@fluentui/react-components';
import { useStyles } from './NoAccessPage.styles';
import { useAppDispatch, useStateSelector } from '@state/index';
import { LinkAction } from '@components/controls/common/LinkAction';
import { initApp } from '@state/app';
import { useNavigate } from 'react-router-dom';

const NoAccessPage = (): React.ReactElement => {
    const { t } = useTranslation();
    const styles = useStyles();
    const moreInformationLink = useStateSelector((c) => c.meta.links?.moreInformation);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch(initApp(navigate));
    }, [dispatch, navigate]);

    return (
        <div className={styles.container}>
            <div className={styles.txtContent}>
                <div>
                    <Subtitle1 as="p" title={t('NoAccessPage.Text1') ?? ''}>
                        {t('NoAccessPage.Text1') ??
                            'The TeamTalk app is for People Leaders to see the latest relevant information to speak to their teams.'}
                    </Subtitle1>
                </div>
                <div>
                    <Subtitle2 as="p" title={t('NoAccessPage.Text2') ?? ''}>
                        {t('NoAccessPage.Text2') ??
                            'If you are seeing this message, you do not have permission to access this app. If you are a People Leader or believe this is in error, please visit our Sharepoint help site.'}
                    </Subtitle2>
                </div>
            </div>
            {!!moreInformationLink && (
                <div className={styles.btnCtn}>
                    <LinkAction
                        appearance="secondary"
                        link={moreInformationLink}
                        title={t('NoAccessPage.MoreInformation.Label') || ''}
                    />
                </div>
            )}
        </div>
    );
};

export default NoAccessPage;
