import { makeStyles, tokens, shorthands } from '@configs/theme/v9';

export const userStyles = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        ...shorthands.flex(1),
        ...shorthands.gap('12px'),
        ...shorthands.padding(0, '24px', '24px')
    },
    subTitle: {
        ...shorthands.margin(0, 0)
    },
    section: {
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '1200px',
        ...shorthands.flex(1),
        '&>p': {
            ...shorthands.margin(0)
        }
    },
    directReportSection: {
        maxWidth: '100%'
    },
    alreadyCommunicatedPerson: {
        '&>span': {
            color: tokens.colorNeutralForegroundDisabled
        }
    },
    personPicker: {
        '& div.ms-PickerPersona-container': {
            backgroundColor: tokens.colorNeutralBackground4
        },
        '& .ms-Persona-primaryText': {
            color: tokens.colorNeutralForeground2
        },
        '& .ms-Button': {
            color: tokens.colorNeutralForeground2
        },
        '& .ms-Button:hover': {
            color: tokens.colorNeutralForeground2Hover,
            backgroundColor: tokens.colorSubtleBackgroundHover
        }
    },
    loader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
        position: 'fixed',
        top: 0,
        left: 0
    }
});
