import React from 'react';
import { useTranslation } from 'react-i18next';
import { useStateSelector } from '@state/index';
import { LinkAction } from '@components/controls/common/LinkAction';
import { QuestionCircle24Regular } from '@fluentui/react-icons';

export interface IHelpLinkAction {
    iconOnlyButton?: boolean;
}

export const HelpLinkAction = ({ iconOnlyButton }: IHelpLinkAction): React.ReactElement => {
    const { t } = useTranslation();
    const helpLink = useStateSelector((c) => c.meta.links?.help);

    return (
        <>
            {!!helpLink && (
                <LinkAction
                    appearance="secondary"
                    link={helpLink}
                    title={t('Common.Actions.Help') || 'Help'}
                    icon={iconOnlyButton ? <QuestionCircle24Regular /> : undefined}
                />
            )}
        </>
    );
};
