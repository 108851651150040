import React from 'react';
import { Checkbox, Text, mergeClasses } from '@fluentui/react-components';
import { useTranslation } from 'react-i18next';
import { useDirectReportsListStyles } from './DirectReportsList.styles';
import { DirectReportWithUserDataModel } from '@api/models/DirectReportWithUserDataModel';
import ReportListItem from './DirectReportItem';
import { SizeModeType } from '@models/ui/SizeModeType';
import { getUsersPhotos } from '@api/services/users';

interface IDirectReportsListProps {
    reports: DirectReportWithUserDataModel[];
    onChangeSelected: (selectedItems: number[]) => void;
    sizeMode: SizeModeType;
    className?: string;
}

const DirectReportsList: React.FC<IDirectReportsListProps> = ({
    reports,
    onChangeSelected,
    sizeMode,
    className
}) => {
    const { t } = useTranslation();
    const styles = useDirectReportsListStyles();

    const [selectedDirectUsersAADIds, setSelectedDirectUsersAADIds] = React.useState<number[]>([]);
    const [userPhotos, setUserPhotos] = React.useState<Map<string, string>>(new Map());
    const activeDirectReports = reports.filter((x) => !x.completed);

    React.useEffect(() => {
        (async () => {
            const userIds = reports.reduce(
                (acc, item) => [
                    ...acc,
                    item.subordinate.aadId,
                    ...(item.actionTakenBy?.aadId ? [item.actionTakenBy?.aadId] : [])
                ],
                [] as string[]
            );
            const photos = await getUsersPhotos(userIds);

            setUserPhotos(photos);
        })();
    }, [reports]);

    React.useEffect(() => {
        onChangeSelected(selectedDirectUsersAADIds);
    }, [onChangeSelected, selectedDirectUsersAADIds]);

    const selectAllDirectUsers = React.useCallback(() => {
        if (selectedDirectUsersAADIds?.length === activeDirectReports.length) {
            setSelectedDirectUsersAADIds([]);
        } else {
            setSelectedDirectUsersAADIds(
                reports.filter((x) => !x.actionTakenBy && !x.completed).map((x) => x.subordinate.id)
            );
        }
    }, [activeDirectReports.length, reports, selectedDirectUsersAADIds?.length]);

    const onDirectReportSelectionChanged = React.useCallback(
        (subordinateId: number, checked: boolean) => {
            let selected: number[] = [];
            if (checked) {
                selected = [...selectedDirectUsersAADIds, subordinateId];
            } else {
                selected = selectedDirectUsersAADIds.filter((c) => c !== subordinateId);
            }
            setSelectedDirectUsersAADIds(selected);
        },
        [selectedDirectUsersAADIds]
    );

    const isReportSelected = React.useCallback(
        (report: DirectReportWithUserDataModel) =>
            report.completed ||
            selectedDirectUsersAADIds.some((x) => x === report.subordinate.id) ||
            !!report.actionTakenBy,
        [selectedDirectUsersAADIds]
    );

    return (
        <section className={className}>
            <Text
                className={styles.subTitleBold}
                as="h5"
                title={t('MessageInteraction.CommsStep.DirectReportListLabel') ?? ''}
            >
                {t('MessageInteraction.CommsStep.DirectReportListLabel')}
            </Text>
            <Checkbox
                checked={selectedDirectUsersAADIds.length === activeDirectReports.length}
                onChange={selectAllDirectUsers}
                label={t('MessageInteraction.CommsStep.SelectAllLabel')}
                className={styles.selectAllReports}
            />
            <ul className={styles.directReportList}>
                {reports.map((report) => (
                    <li
                        key={report.id}
                        className={mergeClasses(
                            styles.directReportItem,
                            sizeMode === SizeModeType.Extra || sizeMode === SizeModeType.Large
                                ? styles.halfCol
                                : styles.fullCol
                        )}
                    >
                        <ReportListItem
                            report={report}
                            userPhotoUri={userPhotos.get(report.subordinate.aadId)}
                            actionTakenByPhotoUri={userPhotos.get(
                                report.actionTakenBy?.aadId ?? ''
                            )}
                            selected={isReportSelected(report)}
                            onSelectionChanged={onDirectReportSelectionChanged}
                        />
                    </li>
                ))}
            </ul>
        </section>
    );
};

export default DirectReportsList;
