import * as React from 'react';
import { useEffect, useLayoutEffect } from 'react';
import { useAppDispatch, useStateSelector } from '@state';
import Loader from '@components/controls/common/Loader';
import { useLayoutStyles } from './Layout.styles';
import { initApp, setAppUIConfig } from '@state/app';
import { useTranslation } from 'react-i18next';
import MessageInteractionView from '@components/MessageInteractionsView';
import { useNavigate } from 'react-router-dom';

export const PageLayout: React.FC<React.PropsWithChildren> = (props: React.PropsWithChildren) => {
    const initialized = useStateSelector((c) => c.app.initialized);
    const showMessageView = useStateSelector((c) => c.selectedMessage.showMessageView);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const styles = useLayoutStyles();
    useEffect(() => {
        dispatch(initApp(navigate));
    }, [dispatch, navigate]);

    useLayoutEffect(() => {
        const updateSize = (): void => {
            dispatch(setAppUIConfig({ width: window.innerWidth }));
        };
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, [dispatch]);

    return (
        <div className={styles.container}>
            {initialized ? (
                showMessageView ? (
                    <MessageInteractionView />
                ) : (
                    <>
                        <div className={styles.page}>{props.children}</div>
                    </>
                )
            ) : (
                <Loader message={t('Common.Loader.Label')} />
            )}
        </div>
    );
};

export const withPageLayout = <T,>(
    WrappedComponent: React.FC<T>
): ((props: T & {}) => React.ReactElement<T, string | React.JSXElementConstructor<T>>) =>
    function InLayout(props: T & {}): React.ReactElement {
        return (
            <PageLayout>
                <WrappedComponent {...props} />
            </PageLayout>
        );
    };

export default PageLayout;
