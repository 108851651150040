import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SizeModeType } from '@models/ui/SizeModeType';
import { routes } from '@configs/pages';
import { getUserPermissions } from '@api/services/permissions';
import { CurrentUserPermissions } from '@api/models';
import { loadMeta } from '../meta';
import { RootState } from '..';

const MOBILE_WIDTH_MAX = 760;
const TABLET_WIDTH_MAX = 1024;
const SMALL_DESC_WIDTH_MAX = 1200;

/* Models */

export interface IAppConfigState {
    containerWidth: number;
    sizeMode: SizeModeType;
    initialized: boolean;
    allowedRoutes: string[];
    currentUserPermissions: CurrentUserPermissions;
}

const initialState: IAppConfigState = {
    sizeMode: SizeModeType.Extra,
    initialized: false,
    containerWidth: 0,
    allowedRoutes: [],
    currentUserPermissions: {
        admin: false,
        approver: false,
        leader: false
    }
};

/** Thunks */
const loadMyPermissions = createAsyncThunk<CurrentUserPermissions, void>(
    'loadMyPermissions',
    async (_, api) => {
        const permissions = await getUserPermissions();
        return permissions;
    }
);

export const initApp = createAsyncThunk<boolean, (url: string) => void, { state: RootState }>(
    'initApp',
    async (navigationCallBack, api) => {
        if (!api.getState().app.initialized) {
            await Promise.all([api.dispatch(loadMyPermissions()), api.dispatch(loadMeta())]);
            const allowedRoutes = api.getState().app.allowedRoutes;
            if (allowedRoutes.length === 1 && allowedRoutes[0] === routes.noAccess) {
                navigationCallBack(routes.noAccess);
                return false;
            } else {
                navigationCallBack(allowedRoutes[0]);
                return true;
            }
        }

        return true;
    }
);

/** Slice */
export const appSlice = createSlice({
    name: 'appConfig',
    initialState,
    reducers: {
        setAppUIConfig: (state, action: PayloadAction<{ width: number }>) => {
            state.containerWidth = action.payload.width;
            if (action.payload.width > SMALL_DESC_WIDTH_MAX) {
                state.sizeMode = SizeModeType.Extra;
            } else if (action.payload.width > TABLET_WIDTH_MAX) {
                state.sizeMode = SizeModeType.Large;
            } else if (action.payload.width < MOBILE_WIDTH_MAX) {
                state.sizeMode = SizeModeType.Mobile;
            } else {
                state.sizeMode = SizeModeType.Tablet;
            }
        },
        setPagesConfig: (state, action: PayloadAction<string[]>) => {
            state.allowedRoutes = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(initApp.fulfilled, (state, action) => {
                state.initialized = action.payload;
            })
            .addCase(loadMyPermissions.fulfilled, (state, action) => {
                const perms = action.payload;
                //Routes configuration based on permissions
                const allowedRoutes: string[] = [];
                if (perms.leader) {
                    allowedRoutes.push(routes.myMessages);
                    allowedRoutes.push(routes.moreMessages);
                }

                if (perms.admin) {
                    allowedRoutes.push(routes.admin);
                }
                if (perms.approver) {
                    allowedRoutes.push(routes.designatedApprover);
                }

                allowedRoutes.push(routes.noAccess);
                state.allowedRoutes = allowedRoutes;

                state.currentUserPermissions = perms;
            });
    }
});

/** Selectors */
export const { setAppUIConfig } = appSlice.actions;

export default appSlice.reducer;
