import { makeStyles, shorthands, tokens } from '@configs/theme/v9';

export const useMessageStrategicFocusBadgeStyles = makeStyles({
    focus: {
        display: 'flex',
        flexDirection: 'row',
        ...shorthands.gap('12px', '0px'),
        justifyContent: 'start',
        alignItems: 'center',
        minWidth: '120px'
    },
    focusTitle: {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        ...shorthands.overflow('hidden'),
        '@supports (-webkit-line-clamp: 2)': {
            whiteSpace: 'initial',
            display: '-webkit-box',
            WebkitLineClamp: 2,
            WebkitBoxOrient: 'vertical'
        }
    },
    imgCtn: {
        position: 'relative',
        width: '32px',
        minWidth: '32px',
        maxWidth: '32px',
        height: '32px',
        ...shorthands.borderRadius('16px'),
        ...shorthands.overflow('hidden')
    },

    image: {
        display: 'block',
        opacity: '1',
        width: '32px',
        height: '32px',
        objectFit: 'cover',
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: tokens.imageBackground
    }
});
