import { makeStyles } from '@configs/theme/v9';

export const useTileViewStyles = makeStyles({
    container: {
        display: 'flex',
        justifyContent: 'start',
        flexWrap: 'wrap'
    },
    containerCompact: {
        display: 'flex',
        justifyContent: 'start',
        flexWrap: 'wrap'
    }
});
