import { TitleValuePair } from '@models/data/TitleValuePair';

export const feedbackTypes = {
    toolFeedback: 'Tool feedback',
    messageFeedback: 'Message feedback'
};

export const getfeedbackTypes = (
    translation: (key: string) => string
): TitleValuePair<string>[] => {
    return [
        {
            id: feedbackTypes.toolFeedback,
            title: translation('Common.Feedback.Dialog.FeedbackType.AppFeedbackOption')
        },
        {
            id: feedbackTypes.messageFeedback,
            title: translation('Common.Feedback.Dialog.FeedbackType.MessageContentOption')
        }
    ];
};
