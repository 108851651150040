import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '..';

import {
    getAdminMessages,
    getApproverMessages,
    getMoreMessages,
    getMyActiveMessagesCount,
    getMyMessages
} from '@api/services/messages';
import { MessageInstanceBasicContentModel } from '@models/data/MessageInstanceBasicContentModel';
import { MessageInstanceBasicData } from '@api/models';
import { IMetaState } from '@state/meta';
import { MessageRequestFilters } from '@api/models/MessageRequestFilters';
import { IFiltersState } from '@state/filters';
import { MessageRequestAdminFilters } from '@api/models/MessageRequestAdminFilters';
import { MessageInstanceAdminBasicData } from '@api/models/MessageInstanceAdminBasicData';

/* Models */

export interface IMessagesState {
    messages: MessageInstanceBasicContentModel[];
    adminMessages: MessageInstanceAdminBasicData[];
    loading: boolean;
    messageCount: number;
    messageCountLoading: boolean;
}

const initialState: IMessagesState = {
    messages: [],
    adminMessages: [],
    loading: false,
    messageCount: 0,
    messageCountLoading: false
};

/* Thunks */

const parseMessages = async (
    meta: IMetaState,
    messages: MessageInstanceBasicData[]
): Promise<MessageInstanceBasicContentModel[]> => {
    const categories = meta.categories;
    const strategicFocuses = meta.strategicFocuses;
    return messages.map((message) => {
        const mCategories = categories.filter((c) => message.categoriesIds.indexOf(c.id) !== -1);
        const mValue = strategicFocuses.find((value) => value.id === message.strategicFocusId);
        return {
            ...message,
            categories: mCategories,
            strategicFocus: mValue || {
                id: -1,
                title: '',
                imageUrl: ''
            }
        };
    });
};
const toFilteringParameters = (filter: IFiltersState): MessageRequestFilters => {
    const cats = filter.messageFilters.categories.map((c) => c.id);
    const focuses = filter.messageFilters.strategicFocuses.map((c) => c.id);

    const critical =
        filter.messageFilters.importanceLevels.length === 1
            ? !!filter.messageFilters.importanceLevels[0].id
            : undefined;

    return {
        text: filter.searchBoxValue,
        categoriesIds: cats,
        strategicFocusesIds: focuses,
        critical: critical,
        endDate: filter.messageFilters.selectedPeriod?.endDateUTC,
        startDate: filter.messageFilters.selectedPeriod?.startDateUTC
    };
};

const toFilteringAdminParameters = (filter: IFiltersState): MessageRequestAdminFilters => {
    const statuses = filter.messageFilters.statuses.map((c) => c.id);
    return {
        ...toFilteringParameters(filter),
        statuses: statuses,
        leaderId: filter.filteredLeader?.id,
        skip: 0,
        count: 50
    };
};

const loadMyMessages = createAsyncThunk<
    MessageInstanceBasicContentModel[],
    void,
    { state: RootState }
>('myMessages', async (_, api) => {
    const state = api.getState();
    const results = await getMyMessages(toFilteringParameters(state.filters));
    return parseMessages(state.meta, results);
});

const loadMyMoreMessages = createAsyncThunk<
    MessageInstanceBasicContentModel[],
    void,
    { state: RootState }
>('moreMessages', async (_, api) => {
    const state = api.getState();
    const results = await getMoreMessages(toFilteringParameters(state.filters));
    return parseMessages(state.meta, results);
});

const loadMessagesForApprover = createAsyncThunk<
    MessageInstanceBasicContentModel[],
    void,
    { state: RootState }
>('approverMessages', async (_, api) => {
    const state = api.getState();
    if (!!state.filters.filteredSLTLeader) {
        const results = await getApproverMessages(
            toFilteringParameters(state.filters),
            state.filters.filteredSLTLeader.id
        );
        return parseMessages(state.meta, results);
    } else {
        return [];
    }
});

const loadMessagesForAdmin = createAsyncThunk<
    MessageInstanceAdminBasicData[],
    void,
    { state: RootState }
>('adminMessages', async (_, api) => {
    const state = api.getState();
    return await getAdminMessages(toFilteringAdminParameters(state.filters));
});

const loadActiveMessagesNumber = createAsyncThunk<number, void, { state: RootState }>(
    'myMessageCount',
    async (api) => {
        const count = await getMyActiveMessagesCount();
        return count;
    }
);

/** Slice */
export const messagesSlice = createSlice({
    name: 'messages',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(loadMyMessages.fulfilled, (state, action) => {
                state.messages = action.payload;
                state.loading = false;
            })
            .addCase(loadMyMessages.pending, (state) => {
                state.loading = true;
            })
            .addCase(loadMyMoreMessages.fulfilled, (state, action) => {
                state.messages = action.payload;
                state.loading = false;
            })
            .addCase(loadMyMoreMessages.pending, (state) => {
                state.loading = true;
            })
            .addCase(loadMessagesForAdmin.fulfilled, (state, action) => {
                state.adminMessages = action.payload;
                state.loading = false;
            })
            .addCase(loadMessagesForAdmin.pending, (state) => {
                state.loading = true;
            })
            .addCase(loadMessagesForApprover.fulfilled, (state, action) => {
                state.messages = action.payload;
                state.loading = false;
            })
            .addCase(loadMessagesForApprover.pending, (state) => {
                state.loading = true;
            })
            .addCase(loadActiveMessagesNumber.fulfilled, (state, action) => {
                state.messageCount = action.payload;
                state.messageCountLoading = false;
            })
            .addCase(loadActiveMessagesNumber.pending, (state) => {
                state.messageCountLoading = true;
            });
    }
});

/** Exports */
export {
    loadMyMessages,
    loadMessagesForAdmin,
    loadMessagesForApprover,
    loadMyMoreMessages,
    loadActiveMessagesNumber
};

export default messagesSlice.reducer;
