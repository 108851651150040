import * as React from 'react';
import { useEffect } from 'react';
import MessageTileView from '@components/MessageTileView';
import { usePageStyles } from './MoreMessages.styles';

import { CommonMessageListView } from '@components/MessageListView';
import { MessagesViewType } from '@models/ui/MessagesViewType';
import { useAppDispatch, useStateSelector } from '@state';

import { loadMyMoreMessages } from '@state/messages';
import ActionsPanel from '@components/ActionsBar';

import { SizeModeType } from '@models/ui/SizeModeType';
import { MessageFiltersPanelAction } from '@components/MessageFiltersPanelAction';
import { FeedbackDialogAction } from '@components/FeedbackDialogAction';
import { MessageSearchBox } from '@components/controls/MessageSearchBox';

import { useTranslation } from 'react-i18next';
import { withPageLayout } from '../PageLayout';
import { loadMyMessage } from '@state/selectedMessage';
import Loader from '@components/controls/common/Loader';
import MessageFilterTags from '@components/MessageFilterTags';
import { MessageInstanceBasicContentModel } from '@models/data/MessageInstanceBasicContentModel';
import { mergeClasses } from '@fluentui/react-components';
import { useScrollBarStyle } from '@components/controls/common/scrollbar.styles';
import { feedbackLocations } from '@configs/feedbackLocations';
import { HelpLinkAction } from '@components/HelpLinkAction';

const MyMessages: React.FunctionComponent = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const styles = usePageStyles();
    const scrollBarStyle = useScrollBarStyle();

    const [sizeMode, containerWidth] = useStateSelector((c) => [
        c.app.sizeMode,
        c.app.containerWidth
    ]);

    const { messages, loading } = useStateSelector((x) => x.messages);
    const [activeView, setActiveView] = React.useState<MessagesViewType>(MessagesViewType.Tiles);

    const loadContent = React.useCallback(() => dispatch(loadMyMoreMessages()), [dispatch]);
    const loadMessage = React.useCallback(
        (m: MessageInstanceBasicContentModel) => {
            dispatch(loadMyMessage(m.id));
        },
        [dispatch]
    );

    useEffect(() => {
        loadContent();
    }, [loadContent]);

    const iconOnlyButtons = sizeMode !== SizeModeType.Extra && sizeMode !== SizeModeType.Large;
    const filtersComponent = (
        <MessageFiltersPanelAction
            applyFiltersCallback={loadContent}
            iconOnlyButton={iconOnlyButtons}
        />
    );
    const filtersTagsComponent =
        sizeMode === SizeModeType.Extra ? (
            <MessageFilterTags changeFiltersCallback={loadContent} />
        ) : (
            <></>
        );

    const searchComponent = <MessageSearchBox searchValueChangedCallback={loadContent} />;
    const additionalActionsComponent = (
        <div className={styles.customActions}>
            <FeedbackDialogAction
                iconOnlyButton={iconOnlyButtons}
                feedbackLocation={feedbackLocations.allMessages}
            />
            <HelpLinkAction iconOnlyButton={iconOnlyButtons} />
        </div>
    );

    return (
        <div className={styles.page}>
            <ActionsPanel
                mainZoneMessage={t('MoreMessagesPage.MainMessage') || ''}
                secondaryZoneMessage={t('MoreMessagesPage.SecondaryMessage') || ''}
                sizeMode={sizeMode}
                viewConfig={{
                    current: activeView,
                    onChange: setActiveView
                }}
                filtersTagsComponent={filtersTagsComponent}
                additionalActionsComponent={additionalActionsComponent}
                searchComponent={searchComponent}
                filtersComponent={filtersComponent}
            />
            {loading ? (
                <Loader message={t('MoreMessagesPage.MessagesLoading') || ''} />
            ) : (
                <div className={mergeClasses(styles.scrollable, scrollBarStyle)}>
                    {activeView === MessagesViewType.Tiles ? (
                        <MessageTileView
                            compact={sizeMode === SizeModeType.Mobile}
                            containerWidth={containerWidth}
                            items={messages}
                            onClick={loadMessage}
                        />
                    ) : (
                        <CommonMessageListView
                            compact={sizeMode === SizeModeType.Mobile}
                            items={messages}
                            onClick={loadMessage}
                        />
                    )}
                </div>
            )}
        </div>
    );
};

export default withPageLayout(MyMessages);
