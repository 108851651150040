import { useStateSelector } from '@state';
import * as React from 'react';
import MessageContentStep from './MessageContentStep';
import MessageCommunicationStep from './MessageCommunicationStep';
import { useMessageInteractionsViewStyles } from './MessageInteractionsView.styles';
import MessageViewHeader from './MessageViewHeader';
import { MessageLoader } from './MessageLoader';
import { mergeClasses } from '@fluentui/react-components';
import { useScrollBarStyle } from '@components/controls/common/scrollbar.styles';

const MessageInteractionView = (): React.ReactElement => {
    const { processingType, message, showMessageView, mode, messageStep } = useStateSelector(
        (c) => c.selectedMessage
    );
    const sizeMode = useStateSelector((c) => c.app.sizeMode);
    const styles = useMessageInteractionsViewStyles();
    const scrollBarStyle = useScrollBarStyle();

    if (!showMessageView) {
        return <></>;
    }

    return (
        <div className={styles.root}>
            {processingType === 'none' && !!message ? (
                <div className={styles.inner}>
                    <MessageViewHeader message={message} sizeMode={sizeMode} />
                    <div className={mergeClasses(styles.scrollable, scrollBarStyle)}>
                        {messageStep === 'content' && (
                            <MessageContentStep message={message} mode={mode} sizeMode={sizeMode} />
                        )}
                        {messageStep === 'communication' && (
                            <MessageCommunicationStep message={message} sizeMode={sizeMode} />
                        )}
                    </div>
                </div>
            ) : (
                <MessageLoader processingType={processingType} />
            )}
        </div>
    );
};

export default MessageInteractionView;
