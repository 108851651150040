import { makeStyles, shorthands, typographyStyles, tokens } from '@fluentui/react-components';

export const usePageStyles = makeStyles({
    page: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'transparent',
        height: '100%'
    },
    scrollable: {
        position: 'initial',
        overflowY: 'auto'
    },
    messageWrapper: {
        ...shorthands.flex(1),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        '>*': {
            ...typographyStyles.title3,
            ...shorthands.padding('0px', '32px'),
            textAlign: 'center'
        }
    },
    managerPicker: {
        width: '265px'
    },
    managerPickerMobile: {
        width: '100%',
        maxWidth: '100%',
        '> *': {
            backgroundColor: tokens.colorNeutralBackground6
        },
        '& button': {
            backgroundColor: 'inherit'
        }
    },
    customActions: {
        display: 'flex',
        flexDirection: 'row',
        ...shorthands.gap('12px'),
        flexWrap: 'nowrap'
    }
});
