import { makeStyles, tokens } from '@configs/theme/v9';

export const useLinkActionStyles = makeStyles({
    btn: { whiteSpace: 'nowrap', height: '32px' },
    iconBtn: {
        whiteSpace: 'nowrap',
        height: '32px',
        width: '36px',
        maxWidth: '36px',
        backgroundColor: tokens.colorNeutralBackground6
    }
});
