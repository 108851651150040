import React from 'react';
import { CompactPeoplePicker, IPersonaProps } from '@fluentui/react';
import { Field, Switch } from '@fluentui/react-components';
import { useTranslation } from 'react-i18next';
import { useOtherColleaguesSectionStyles } from './OtherColleaguesSection.styles';
import { getUsersPhotos, searchUsers } from '@api/services/users';

interface IOtherColleaguesSectionProps {
    excludeSubordinateIds: number[];
    onSelectionChanged: (userIds: number[]) => void;
    className?: string;
}

const getUsersWithImagesByName: (name: string) => Promise<IPersonaProps[]> = async (
    name: string
) => {
    const users = await searchUsers(name);
    const userIds = users.map((x) => x.aadId);
    const photos = await getUsersPhotos(userIds);

    return users.map(
        (user) =>
            ({
                text: user.name,
                key: user.id,
                imageUrl: photos.get(user.aadId)
            }) as IPersonaProps
    );
};

const OtherColleaguesSection: React.FC<IOtherColleaguesSectionProps> = ({
    excludeSubordinateIds,
    onSelectionChanged,
    className
}) => {
    const { t } = useTranslation();
    const styles = useOtherColleaguesSectionStyles();
    const [otherColleaguesEnabled, setOtherColleaguesEnabled] = React.useState(false);

    const colleaguesChanged = React.useCallback(
        (ev: { currentTarget: { checked: boolean | ((prevState: boolean) => boolean) } }) => {
            setOtherColleaguesEnabled(ev.currentTarget.checked);
        },
        []
    );

    const onFilterChanged = React.useCallback(
        (
            filterText: string,
            currentPersonas?: IPersonaProps[],
            idsToExclude?: number[],
            limitResults?: number
        ): IPersonaProps[] | Promise<IPersonaProps[]> => {
            if (filterText) {
                const excludedUsersAAdIds = new Set([
                    ...(currentPersonas?.map((x) => x.key) ?? []),
                    ...(idsToExclude ?? [])
                ]);
                return getUsersWithImagesByName(filterText).then((users) =>
                    users.filter((x) => !excludedUsersAAdIds.has(x.key))
                );
            } else {
                return [];
            }
        },
        []
    );

    const onUsersSelected = React.useCallback(
        (items: IPersonaProps[] | undefined) => {
            onSelectionChanged(!!items ? items.map((x) => parseInt(x.key as string)) : []);
        },
        [onSelectionChanged]
    );

    return (
        <section className={className}>
            <Switch
                checked={otherColleaguesEnabled}
                onChange={colleaguesChanged}
                label={t('MessageInteraction.CommsStep.AddAnotherColleaguesLabel')}
            />
            {otherColleaguesEnabled && (
                <Field label={t('MessageInteraction.CommsStep.AddAnotherColleaguesDescription')}>
                    <CompactPeoplePicker
                        onResolveSuggestions={(...args) =>
                            onFilterChanged(...args, excludeSubordinateIds)
                        }
                        pickerCalloutProps={{
                            className: styles.pickerCallout
                        }}
                        getTextFromItem={(persona: IPersonaProps) => persona.text as string}
                        className={styles.personPicker}
                        selectionAriaLabel={'Selected contacts'}
                        removeButtonAriaLabel={'Remove'}
                        pickerSuggestionsProps={{
                            suggestionsHeaderText: t('PeoplePicker.SuggestionsHeaderText') ?? '',
                            mostRecentlyUsedHeaderText:
                                t('PeoplePicker.MostRecentlyUsedHeaderText') ?? '',
                            noResultsFoundText: t('PeoplePicker.NoResultsFoundText') ?? '',
                            loadingText: t('PeoplePicker.LoadingText') ?? '',
                            suggestionsAvailableAlertText:
                                t('PeoplePicker.SuggestionsAvailableAlertText') ?? '',
                            suggestionsContainerAriaLabel:
                                t('PeoplePicker.SuggestionsContainerAriaLabel') ?? ''
                        }}
                        onChange={onUsersSelected}
                        resolveDelay={600}
                    />
                </Field>
            )}
        </section>
    );
};

export default OtherColleaguesSection;
