import React from 'react';
import { Switch } from '@fluentui/react-components';
import { useTranslation } from 'react-i18next';
import TextAreaControl from '@components/controls/common/TextArea';

interface IAnnotationTextSectionProps {
    onAnnotationChanged: (value: string | undefined) => void;
    className?: string;
    annotationText?: string;
}

const TOTAL_LIMIT = 2000;

const AnnotationTextSection: React.FC<IAnnotationTextSectionProps> = ({
    onAnnotationChanged,
    annotationText,
    className
}) => {
    const { t } = useTranslation();
    const [annotationEnabled, setAnnotationEnabled] = React.useState(false);

    const onAddAnnotationChange = React.useCallback(
        (ev: { currentTarget: { checked: boolean | ((prevState: boolean) => boolean) } }) => {
            setAnnotationEnabled(ev.currentTarget.checked);
            if (!ev.currentTarget.checked) {
                onAnnotationChanged(undefined);
            }
        },
        [onAnnotationChanged]
    );
    return (
        <section className={className}>
            <Switch
                checked={annotationEnabled}
                onChange={onAddAnnotationChange}
                label={t('MessageInteraction.CommsStep.AddAnnotationForPeopleLeaders')}
            />
            {annotationEnabled && (
                <TextAreaControl
                    label={t('MessageInteraction.CommsStep.AnnotationForPeopleLeadersInfo') || ''}
                    maxLength={TOTAL_LIMIT}
                    maxLengthMessagePostfix={
                        t('MessageInteraction.CommsStep.AnnotationTextLimit') || ''
                    }
                    placeholder={
                        t('MessageInteraction.CommsStep.AnnotationTextAreaPlaceholder') ?? ''
                    }
                    onValueChanged={onAnnotationChanged}
                    appearance="filled-darker"
                    value={annotationText || ''}
                />
            )}
        </section>
    );
};

export default AnnotationTextSection;
