import { makeStyles, shorthands } from '@fluentui/react-components';
export const useStyles = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'transparent',
        height: '100%',
        overflowY: 'hidden',
        maxWidth: '640px',
        maxHeight: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        rowGap: '20px',
        ...shorthands.margin('0', 'auto')
    },
    txtContent: {
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
        ...shorthands.padding('0', '12px'),
        rowGap: '20px'
    },
    btnCtn: {
        display: 'block',
        textAlign: 'center',
        ...shorthands.padding('0', '12px')
    }
});
