import React from 'react';
import { Button } from '@fluentui/react-components';
import { app } from '@microsoft/teams-js';
import { useLinkActionStyles } from './LinkAction.styles';

export interface ILinkActionProps {
    title?: string;
    link: string;
    appearance: 'primary' | 'secondary';
    icon?: React.ReactElement;
}

export const LinkAction = ({
    title,
    link,
    appearance,
    icon
}: ILinkActionProps): React.ReactElement => {
    const styles = useLinkActionStyles();

    const handleLinkClick = React.useCallback(
        async (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>): Promise<void> => {
            event.preventDefault();
            if (!app.isInitialized) {
                await app.initialize();
            }
            app.openLink(link);
        },
        [link]
    );

    return !icon ? (
        <Button
            as="a"
            appearance={appearance}
            href={link}
            aria-label={title}
            onClick={handleLinkClick}
            target="blank"
            className={styles.btn}
        >
            {title}
        </Button>
    ) : (
        <Button
            as="a"
            appearance="outline"
            size="medium"
            href={link}
            className={styles.iconBtn}
            onClick={handleLinkClick}
            icon={icon}
            iconPosition="before"
        ></Button>
    );
};
