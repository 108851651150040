import * as React from 'react';
import { useEffect } from 'react';
import MessageTileView from '@components/MessageTileView';
import { usePageStyles } from './DesignatedApproverPage.styles';
import { CommonMessageListView } from '@components/MessageListView';
import { MessagesViewType } from '@models/ui/MessagesViewType';
import { useAppDispatch, useStateSelector } from '@state';
import { loadMessagesForApprover } from '@state/messages';
import ActionsPanel from '@components/ActionsBar';
import { SizeModeType } from '@models/ui/SizeModeType';
import { MessageFiltersPanelAction } from '@components/MessageFiltersPanelAction';
import { FeedbackDialogAction } from '@components/FeedbackDialogAction';
import { MessageSearchBox } from '@components/controls/MessageSearchBox';

import { useTranslation } from 'react-i18next';
import { withPageLayout } from '../PageLayout';
import { loadMessageByApprover } from '@state/selectedMessage';
import { PeoplePicker } from '@components/controls/common/PeoplePicker';
import { setSLTLeaderFilter } from '@state/filters';
import { UserBasicData } from '@api/models/UserBasicData';
import { searchSLTUsers } from '@api/services/users';
import Loader from '@components/controls/common/Loader';
import { Text, mergeClasses } from '@fluentui/react-components';
import MessageFilterTags from '@components/MessageFilterTags';
import { MessageInstanceBasicContentModel } from '@models/data/MessageInstanceBasicContentModel';
import { useScrollBarStyle } from '@components/controls/common/scrollbar.styles';
import { feedbackLocations } from '@configs/feedbackLocations';
import { HelpLinkAction } from '@components/HelpLinkAction';

const DesignatedApprover: React.FunctionComponent = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const styles = usePageStyles();
    const scrollBarStyle = useScrollBarStyle();

    const { sizeMode, containerWidth } = useStateSelector((c) => c.app);
    const filteredSLTLeader = useStateSelector((c) => c.filters.filteredSLTLeader);
    const { messages, loading } = useStateSelector((x) => x.messages);

    const loadContent = React.useCallback(() => dispatch(loadMessagesForApprover()), [dispatch]);
    const onSelectSLTLeader = React.useCallback(
        (leader: UserBasicData | undefined) => {
            dispatch(setSLTLeaderFilter(leader));
            loadContent();
        },
        [dispatch, loadContent]
    );

    const loadMessage = React.useCallback(
        (m: MessageInstanceBasicContentModel) => {
            dispatch(loadMessageByApprover(m.id));
        },
        [dispatch]
    );

    const [activeView, setActiveView] = React.useState<MessagesViewType>(MessagesViewType.Tiles);

    useEffect(() => {
        loadContent();
    }, [loadContent]);

    const iconOnlyButtons = sizeMode !== SizeModeType.Extra && sizeMode !== SizeModeType.Large;

    const filtersComponent = (
        <MessageFiltersPanelAction
            applyFiltersCallback={loadContent}
            iconOnlyButton={iconOnlyButtons}
        />
    );

    const filtersTagsComponent =
        sizeMode === SizeModeType.Extra ? (
            <MessageFilterTags changeFiltersCallback={loadContent} />
        ) : (
            <></>
        );
    const searchComponent = <MessageSearchBox searchValueChangedCallback={loadContent} />;
    const additionalActionsComponent = (
        <div className={styles.customActions}>
            <FeedbackDialogAction
                iconOnlyButton={iconOnlyButtons}
                feedbackLocation={feedbackLocations.designatedApprover}
            />
            <HelpLinkAction iconOnlyButton={iconOnlyButtons} />
        </div>
    );

    const extraFiltersComponent = (
        <PeoplePicker
            className={
                sizeMode === SizeModeType.Extra || sizeMode === SizeModeType.Large
                    ? styles.managerPicker
                    : styles.managerPickerMobile
            }
            onSearchUsers={searchSLTUsers}
            selectedUser={filteredSLTLeader}
            onSelectUser={onSelectSLTLeader}
            noResultText={t('DesignatedApproverPage.SLTPicker.NoResults') || ''}
            placeholder={t('DesignatedApproverPage.SLTPicker.Placeholder') || ''}
            fullWidthMode
            preload
        />
    );

    return (
        <div className={styles.page}>
            <ActionsPanel
                mainZoneMessage={
                    filteredSLTLeader
                        ? t('DesignatedApproverPage.MainMessage')
                              .replace('{SelectedName}', filteredSLTLeader.name)
                              .replace('{messageNumber}', messages.length.toString())
                        : ''
                }
                secondaryZoneMessage={
                    filteredSLTLeader
                        ? t('DesignatedApproverPage.SecondaryMessage')?.replace(
                              '{SelectedName}',
                              filteredSLTLeader.name
                          )
                        : ''
                }
                sizeMode={sizeMode}
                viewConfig={{
                    current: activeView,
                    onChange: setActiveView
                }}
                filtersTagsComponent={filtersTagsComponent}
                additionalActionsComponent={additionalActionsComponent}
                searchComponent={searchComponent}
                filtersComponent={filtersComponent}
                extraFiltersComponent={extraFiltersComponent}
            />
            {filteredSLTLeader &&
                (loading ? (
                    <Loader
                        message={t('DesignatedApproverPage.MessagesLoading').replace(
                            '{manager}',
                            filteredSLTLeader?.name || ''
                        )}
                    />
                ) : (
                    <div className={mergeClasses(styles.scrollable, scrollBarStyle)}>
                        {activeView === MessagesViewType.Tiles ? (
                            <MessageTileView
                                containerWidth={containerWidth}
                                items={messages}
                                onClick={loadMessage}
                                compact={sizeMode === SizeModeType.Mobile}
                            />
                        ) : (
                            <CommonMessageListView
                                compact={sizeMode === SizeModeType.Mobile}
                                items={messages}
                                onClick={loadMessage}
                            />
                        )}
                    </div>
                ))}

            {!filteredSLTLeader && (
                <div className={styles.messageWrapper}>
                    <Text as="h2">{t('DesignatedApproverPage.NoSLTSelected')}</Text>
                </div>
            )}
        </div>
    );
};

export default withPageLayout(DesignatedApprover);
