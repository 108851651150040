import {
    TableColumnDefinition,
    TableColumnId,
    TableHeaderCellProps,
    TableRowData,
    createTableColumn,
    useTableFeatures,
    useTableSort
} from '@fluentui/react-components';
import { MessageInstanceBasicContentModel } from '@models/data/MessageInstanceBasicContentModel';

export type MessageInstanceBasicContentModelKeys = keyof MessageInstanceBasicContentModel;

const titleColumnId: MessageInstanceBasicContentModelKeys = 'title';
const categoryColumnId: MessageInstanceBasicContentModelKeys = 'categories';
const instancePublishingDateUTCColumnId: MessageInstanceBasicContentModelKeys =
    'instancePublishingDateUTC';
const strageticFocusId: MessageInstanceBasicContentModelKeys = 'strategicFocus';
const importanceLevelColumnId: MessageInstanceBasicContentModelKeys = 'critical';

const columns: TableColumnDefinition<MessageInstanceBasicContentModel>[] = [
    createTableColumn<MessageInstanceBasicContentModel>({
        columnId: titleColumnId,
        compare: (a, b) => {
            return a.title.localeCompare(b.title);
        }
    }),
    createTableColumn<MessageInstanceBasicContentModel>({
        columnId: categoryColumnId,
        compare: (a, b) => {
            let res = a.categories.length.toString().localeCompare(b.categories.length.toString());
            if (res === 0) {
                const aStr = a.categories.map((c) => c.title).join();
                const bStr = b.categories.map((c) => c.title).join();
                res = aStr.localeCompare(bStr);
            }
            return res;
        }
    }),

    createTableColumn<MessageInstanceBasicContentModel>({
        columnId: strageticFocusId,
        compare: (a, b) => {
            return a.strategicFocus.title.localeCompare(b.strategicFocus.title);
        }
    }),

    createTableColumn<MessageInstanceBasicContentModel>({
        columnId: importanceLevelColumnId,
        compare: (a, b) => {
            return `${b.critical}`.localeCompare(`${a.critical}`);
        }
    }),
    createTableColumn<MessageInstanceBasicContentModel>({
        columnId: instancePublishingDateUTCColumnId,
        compare: (a, b) => {
            return +(a.instancePublishingDateUTC > b.instancePublishingDateUTC);
        }
    })
];

const useTableConfiguration = (
    items: MessageInstanceBasicContentModel[]
): {
    rows: TableRowData<MessageInstanceBasicContentModel>[];
    headerSortProps: (columnId: TableColumnId) => Partial<TableHeaderCellProps>;
} => {
    const {
        getRows,
        sort: { getSortDirection, toggleColumnSort, sort }
    } = useTableFeatures(
        {
            columns,
            items
        },
        [useTableSort({})]
    );

    const headerSortProps = (columnId: TableColumnId): Partial<TableHeaderCellProps> => ({
        onClick: (e: React.MouseEvent) => {
            toggleColumnSort(e, columnId);
        },

        sortDirection: getSortDirection(columnId)
    });

    const rows = sort(getRows());

    return {
        rows,
        headerSortProps
    };
};

const ColumnsIds = {
    titleColumnId,
    categoryColumnId,
    instancePublishingDateUTCColumnId,
    strageticFocusId,
    importanceLevelColumnId
};

export { useTableConfiguration, ColumnsIds };
