import * as React from 'react';
import { useFindOutMoreButtonStyles } from './FindOutMoreButton.styles';
import { useTranslation } from 'react-i18next';
import { Button, mergeClasses } from '@fluentui/react-components';
import { SizeModeType } from '@models/ui/SizeModeType';

export interface IFindOutMoreButton {
    className?: string;
    actionLinkUrl?: string;
    actionLabel?: string;
    sizeMode: SizeModeType;
}
export const FindOutMoreButton = ({
    actionLabel,
    actionLinkUrl,
    className,
    sizeMode
}: IFindOutMoreButton): React.ReactElement => {
    const styles = useFindOutMoreButtonStyles();
    const { t } = useTranslation();

    const onFindOutMoreClick = React.useCallback((url: string): void => {
        if (!!url) {
            window.open(url, '_blank');
        }
    }, []);

    return (
        <section className={mergeClasses(styles.findOutMoreSection, className)}>
            {!!actionLinkUrl && (
                <Button
                    appearance="primary"
                    style={{
                        minWidth: `${sizeMode === SizeModeType.Mobile ? '100%' : ''}`
                    }}
                    onClick={() => onFindOutMoreClick(actionLinkUrl)}
                >
                    {actionLabel ?? t('MessageInteraction.ContentStep.FindoutMoreButton')}
                </Button>
            )}
        </section>
    );
};
